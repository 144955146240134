import { endOfMonth, parseISO, startOfMonth, subMonths } from 'date-fns'
import asyncPool from 'tiny-async-pool'

import { toISOdate } from 'app/lib/utils/date'
import { EligibilityRecord, UsageRange } from 'app/models/scribe.models'

import { listEligibilityRecords } from './scribe.api'

export function withAuthorization(token: string, headers: HeadersInit = {}): HeadersInit {
  return { ...headers, Authorization: `Bearer ${token}` }
}

export function withSearchParams(href: string, params: Record<string, any> = {}): string {
  const url = new URL(href)

  Object.entries(params)
    // exclude '', `undefined`, `null` and NaN
    .filter(([_key, value]) => !!value || value === 0 || value === false)
    .forEach(([key, value]) => {
      if (value.constructor === Object) {
        url.searchParams.set(key, JSON.stringify(value))
      } else if (typeof value === 'number') {
        url.searchParams.set(key, value.toString())
      } else {
        url.searchParams.set(key, value)
      }
    })

  return url.toString()
}

export enum HttpErrorType {
  CLIENT = 'client',
  SERVER = 'server',
}
const parseScribeV2ErrorDetail = (body?: Record<string, any>) => {
  return body && 'detail' in body ? body['detail'] : undefined
}
const parseScribeV1ErrorCode = (body?: Record<string, any>) => {
  return body && 'errors' in body && Array.isArray(body['errors']) && body['errors'][0]
    ? body['errors'][0].code
    : undefined
}

export enum ScribeErrorCode {
  NOT_FOUND = 'not_found',
  DUPLICATED_ENTITY = 'duplicated_entity',
}
export class HttpError extends Error {
  public type: HttpErrorType | null
  public detail?: Record<string, any>
  public code?: ScribeErrorCode | string
  constructor(
    public status: number,
    public statusText: string,
    _body?: Record<string, any>,
  ) {
    super()
    this.type = null
    if (status >= 400 && status < 500) {
      this.type = HttpErrorType.CLIENT
    } else if (status >= 500) {
      this.type = HttpErrorType.SERVER
    }
    this.detail = parseScribeV2ErrorDetail(_body)
    this.code = parseScribeV1ErrorCode(_body)
  }
}

export function parseJson<T>(response: Response): Promise<T> {
  // promise that resolves to null if response is not JSON parsable
  let parsingBody: Promise<T> = response.json().catch(() => Promise.resolve(null))

  if (response.ok) {
    return parsingBody
  } else {
    const { status, statusText } = response
    return parsingBody.then((parsedBody) => {
      return Promise.reject(
        new HttpError(status, statusText, parsedBody as Record<string, any> | undefined),
      )
    })
  }
}

export function calculateUsageRange(invoiceIssueDate: string): UsageRange {
  const date = parseISO(invoiceIssueDate)
  const dateWithUsageMonth = subMonths(date, 1)
  const startDate = startOfMonth(dateWithUsageMonth)
  const endDate = endOfMonth(dateWithUsageMonth)

  const usageRange: UsageRange = {
    start_date: toISOdate(startDate),
    end_date: toISOdate(endDate),
  }

  return usageRange
}

export async function fetchAllEligibilityRecords(
  token: string,
  organizationId: string,
  eligible_on_or_after?: string,
  status?: string,
): Promise<ReadonlyArray<EligibilityRecord>> {
  const pageSize = 500
  const params = {
    limit: pageSize,
    ...(eligible_on_or_after && { eligible_on_or_after }),
    ...(status && { status }),
  }
  const fetchPage = (offset: number) =>
    listEligibilityRecords(token, organizationId, { offset: offset, ...params })
  const firstResponse = await fetchPage(0)
  const totalItems = firstResponse.pagination.total
  const totalPages = Math.ceil(totalItems / pageSize)
  const offsets = [...Array(totalPages)].map((_, ix) => ix * pageSize)

  const remainingResponses = await asyncPoolAll(5, offsets.slice(1), fetchPage)
  return remainingResponses.reduce((prev, curr) => prev.concat(curr.records), firstResponse.records)
}

/**
 * Convert the output of `asyncPool` from
 *  an async result generator to an array of results
 *  doc: https://github.com/rxaviers/async-pool#migrating-from-1x
 */
export const asyncPoolAll = async <IN, OUT>(
  poolLimit: number,
  array: ReadonlyArray<IN>,
  iteratorFn: (generator: IN) => Promise<OUT>,
): Promise<OUT[]> => {
  const results = []
  for await (const result of asyncPool<IN, OUT>(poolLimit, array, iteratorFn)) {
    results.push(result)
  }
  return results
}
