import React, { useState } from 'react'

import { Button, TableCell, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ClickableRow } from 'app/lib/components/ClickableRow'
import { Label, LabelType } from 'app/lib/components/Label'
import {
  EligibilityRecordStatus,
  Pagination,
  UserEligibilityRecordData,
} from 'app/models/scribe.models'

import { ConfirmEnrolmentChange } from './ConfirmEnrolmentChange'
import { UserInfoTable } from './UserInfoTable'

const TABLE_HEADERS = [
  'userProfile.eligibilityRecord.firstName',
  'userProfile.eligibilityRecord.lastName',
  'userProfile.eligibilityRecord.eligibilityRecordId',
  'userProfile.eligibilityRecord.uniqueIdentifier',
  'userProfile.eligibilityRecord.organizationEnrolmentCode',
  'userProfile.eligibilityRecord.status',
  'userProfile.eligibilityRecord.organization',
] as const

type TableProps = Readonly<{
  eligibilityRecords: UserEligibilityRecordData[]
  pagination: Pagination
  setPagination: (params: Pagination) => void
  pageTitle: string
}>

export const EligibilityRecord: React.FC<TableProps> = ({
  eligibilityRecords,
  pagination,
  setPagination,
  pageTitle,
}) => {
  const { t } = useTranslation()
  const [openConfirmEnrolChange, setOpenConfirmEnrolChange] = useState<boolean>(false)
  const [eligibilityId, setEligibilityId] = useState<number>(0)

  const cells = [
    ...TABLE_HEADERS.map((i18nKey) => (
      <TableCell key={i18nKey} align="left" padding="normal">
        {t(i18nKey)}
      </TableCell>
    )),
    <TableCell key="additional-column" align="left" padding="normal" />,
  ]

  const updateRecordStatusFormat = (status: string) => {
    return status.charAt(0).toUpperCase() + status.slice(1)
  }

  const unEnrolUser = (event: React.MouseEvent<HTMLButtonElement>, id: number) => {
    event.stopPropagation()
    setEligibilityId(id)
    setOpenConfirmEnrolChange(true)
  }

  const rows = eligibilityRecords?.map((record: UserEligibilityRecordData) => (
    <ClickableRow
      key={record.id}
      to={`/organizations/${record.organization.id}/members/${record.id}`}
      newTabOnClick={true}
    >
      <TableCell>{record.attributes.firstName}</TableCell>
      <TableCell>{record.attributes.lastName}</TableCell>
      <TableCell>{record.id}</TableCell>
      <TableCell>
        {record.attributes.organizationEnrolmentCode ? null : record.attributes.uniqueIdentifier}
      </TableCell>
      <TableCell>{record.attributes.organizationEnrolmentCode}</TableCell>
      <TableCell>
        <Typography variant="body2">
          <Label
            type={
              record.status === EligibilityRecordStatus.ACTIVE
                ? LabelType.DEFAULT
                : LabelType.ARCHIVED
            }
            text={updateRecordStatusFormat(record.status)}
            textProps={{ px: 2, py: 0.5, borderRadius: 10 }}
            boxProps={{ mt: 0 }}
          />
        </Typography>
      </TableCell>
      <TableCell>{record.organization.name}</TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <Button variant="outlined" onClick={(event) => unEnrolUser(event, record.id)}>
          {t('userProfile.eligibilityRecord.unEnrol')}
        </Button>
      </TableCell>
    </ClickableRow>
  ))

  return (
    <>
      <UserInfoTable
        cells={cells}
        rows={rows}
        pagination={pagination}
        pageTitle={pageTitle}
        emptyStateMessage={t('userProfile.eligibilityRecord.emptyStateMessage')}
        setPagination={setPagination}
        isEligibilityRecord
        tableTitle={t('userProfile.eligibilityRecord.eligibilityRecordHeader')}
        hasEntries={eligibilityRecords.length > 0}
      />
      {openConfirmEnrolChange && (
        <ConfirmEnrolmentChange
          openModal={openConfirmEnrolChange}
          onOpenConfirmation={setOpenConfirmEnrolChange}
          unEnrol
          eligibilityId={eligibilityId}
          modalTitle={`${t('userProfile.eligibilityRecord.titleUnenrol')} ${pageTitle}`}
        />
      )}
    </>
  )
}
