import { useMemo } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ClickableRow } from 'app/lib/components/ClickableRow'
import { AdminAreaTrKeys } from 'app/lib/constants'
import { formatDate, getMonth } from 'app/lib/utils/date'
import { getProvinceName } from 'app/lib/utils/helpers'
import { EligibilityRecord } from 'app/models/scribe.models'
import { getApplicationConfig } from 'config'

import { DEFAULT_LIMIT } from '..'

interface Props {
  records: ReadonlyArray<EligibilityRecord>
  page: number
  onPageChange: (page: number) => void
  count: number
  plansIdToLabel: {
    [key: string]: string
  }
  excludeHeaders?: HeaderKeys[]
}

export enum HeaderKeys {
  uniqueIdentifier = 'uniqueIdentifier',
  firstName = 'firstName',
  lastName = 'lastName',
  dateOfBirth = 'dateOfBirth',
  birthMonthDay = 'birthMonthDay',
  planLabel = 'planLabel',
  province = 'province',
  activationDate = 'activationDate',
  deactivationDate = 'deactivationDate',
}

export type HeaderValues = Partial<Record<HeaderKeys, string>> & {
  [HeaderKeys.uniqueIdentifier]: string
  [HeaderKeys.activationDate]: string
  [HeaderKeys.deactivationDate]: string
}

interface HeaderType {
  key?: HeaderKeys
  name: HeaderKeys | typeof AdminAreaTrKeys
  width?: number
}

const { region } = getApplicationConfig()

const HEADER_NAMES = [
  { name: HeaderKeys.uniqueIdentifier, width: 275 },
  { name: HeaderKeys.firstName },
  { name: HeaderKeys.lastName },
  { name: HeaderKeys.dateOfBirth },
  { name: HeaderKeys.birthMonthDay },
  { name: HeaderKeys.planLabel },
  { key: HeaderKeys.province, name: AdminAreaTrKeys[region] },
  { name: HeaderKeys.activationDate },
  { name: HeaderKeys.deactivationDate },
] as HeaderType[]

const formatBirthMonthDay = (birthMonthDay: string, locale: string) => {
  const [month, day] = birthMonthDay.split('-')
  return `${getMonth(new Date(`${month}-01-01`), locale, 'short')} ${day}`
}
export const TableDisplay: React.FC<Props> = ({
  records,
  page,
  onPageChange,
  count,
  plansIdToLabel,
  excludeHeaders,
}) => {
  const { t: tHeader } = useTranslation(undefined, {
    keyPrefix: 'eligibilityRecordListPage.eligibilityRecordsTable.headers',
  })
  const {
    t,
    i18n: { resolvedLanguage = 'en' },
  } = useTranslation()

  const [headers, excludeHeaderKeys] = useMemo(() => {
    const excludeKeys = new Set(excludeHeaders)
    const values = HEADER_NAMES.filter(({ key, name }) => {
      const keyOrName = key || (name as HeaderKeys)
      return !excludeKeys.has(keyOrName)
    })
    return [values, excludeKeys]
  }, [excludeHeaders])

  const cells = headers.map(({ name, width }) => (
    <TableCell key={name} sx={{ width }} align="left">
      {tHeader(name)}
    </TableCell>
  ))

  const rows = records.map(({ id, organizationId, attributes, eligibleIntervals }) => {
    const eligibleInterval = eligibleIntervals[eligibleIntervals.length - 1]
    return (
      <ClickableRow key={id} to={`/organizations/${organizationId}/members/${id}`} testId={id}>
        {!excludeHeaderKeys.has(HeaderKeys.uniqueIdentifier) && (
          <TableCell
            title={attributes.uniqueIdentifier}
            sx={{ maxWidth: 275, overflowWrap: 'break-word' }}
          >
            {attributes.uniqueIdentifier}
          </TableCell>
        )}
        {!excludeHeaderKeys.has(HeaderKeys.firstName) && (
          <TableCell sx={{ maxWidth: 100, overflowWrap: 'break-word' }}>
            <Typography variant="subtitle2">{attributes.firstName}</Typography>
          </TableCell>
        )}
        {!excludeHeaderKeys.has(HeaderKeys.lastName) && (
          <TableCell sx={{ maxWidth: 100, overflowWrap: 'break-word' }}>
            <Typography variant="subtitle2">{attributes.lastName}</Typography>
          </TableCell>
        )}
        {!excludeHeaderKeys.has(HeaderKeys.dateOfBirth) && (
          <TableCell>
            {attributes.dateOfBirth && formatDate(attributes.dateOfBirth, resolvedLanguage)}
          </TableCell>
        )}
        {!excludeHeaderKeys.has(HeaderKeys.birthMonthDay) && (
          <TableCell>
            {attributes.birthMonthDay &&
              formatBirthMonthDay(attributes.birthMonthDay, resolvedLanguage)}
          </TableCell>
        )}
        {!excludeHeaderKeys.has(HeaderKeys.planLabel) && (
          <TableCell>{eligibleInterval ? plansIdToLabel[eligibleInterval.planId] : '-'}</TableCell>
        )}
        {!excludeHeaderKeys.has(HeaderKeys.province) && (
          <TableCell>
            {attributes.province && getProvinceName(attributes.province, '', t)},
          </TableCell>
        )}
        {!excludeHeaderKeys.has(HeaderKeys.activationDate) && (
          <TableCell>
            {eligibleInterval ? formatDate(eligibleInterval.startDate, resolvedLanguage) : '-'}
          </TableCell>
        )}
        {!excludeHeaderKeys.has(HeaderKeys.deactivationDate) && (
          <TableCell>
            {eligibleInterval ? formatDate(eligibleInterval.endDate, resolvedLanguage) : '-'}
          </TableCell>
        )}
      </ClickableRow>
    )
  })

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>{cells}</TableRow>
        </TableHead>
        <TableBody data-testid="eligibility-record-table">{rows}</TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              page={page}
              rowsPerPage={DEFAULT_LIMIT}
              rowsPerPageOptions={[]}
              count={count}
              showFirstButton
              showLastButton
              onPageChange={(_event, pageValue) => onPageChange(pageValue)}
              getItemAriaLabel={(type) => t(`global.pagination.${type}`)}
              labelDisplayedRows={({ from, to, count: itemsCount }) =>
                itemsCount > 0
                  ? t('global.pagination.of', { from, to, count: itemsCount })
                  : t('global.pagination.of_more_than', { from, to })
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
