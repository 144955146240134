import { useMemo } from 'react'

import AccountBox from '@mui/icons-material/AccountBox'
import ApartmentIcon from '@mui/icons-material/Apartment'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import FolderSharedIcon from '@mui/icons-material/FolderShared'
import PeopleIcon from '@mui/icons-material/People'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { SvgIcon } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { ScribePermission } from 'app/lib/constants'
import withProtectedComponent from 'app/lib/hoc/withProtectedComponent'
import { minOnePlanHasOption, minOnePlanHasService } from 'app/lib/utils/helpers'
import { ServiceLabels, ServiceOptionLabels } from 'app/models/scribe.models'
import { useGetOrganizationPlansQuery } from 'app/redux/scribeApi'
import { OrganizationProfileIcon, ReportsIcon } from 'assets/images'

interface ItemProps {
  link: string
  sublink?: string
  title: string
  icon: JSX.Element
}

interface SidebarItemProps extends ItemProps {
  permission?: ScribePermission
  visible: boolean
}

interface MenuItemProps {
  organizationId: string
  item: ItemProps
}

const drawerWidth = 200

const MENU_BUTTON_HOVER_STATE = {
  '&:hover': () => ({
    '.MuiListItemText-secondary, .MuiListItemIcon-root': {
      color: 'accent.main',
    },
  }),
}

const MenuItem = ({ item, organizationId }: MenuItemProps) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const linkUrl = organizationId ? `/organizations/${organizationId}${item.link}` : item.link
  const selected: boolean = useMemo(() => {
    const isBaseLinkSelected = pathname.indexOf(linkUrl) !== -1

    if (isBaseLinkSelected) {
      return isBaseLinkSelected
    }

    if (item.sublink) {
      return pathname.indexOf(item.sublink) !== -1
    }

    return false
  }, [pathname, linkUrl, item.sublink])

  return (
    <ListItem key={item.link} disablePadding>
      <ListItemButton
        component={Link}
        selected={selected}
        to={linkUrl}
        sx={{
          gap: 1,
          pl: 3.75,
          py: 2.5,
          ...MENU_BUTTON_HOVER_STATE,
          '&.Mui-selected': (theme) => ({
            pl: 3.25,
            borderLeft: `4px solid ${theme.palette.accent.main}`,
            '.MuiListItemText-secondary, .MuiListItemIcon-root': {
              color: 'accent.main',
            },
          }),
        }}
      >
        <ListItemIcon sx={{ color: 'secondary.main', minWidth: 'initial' }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          // @ts-ignore
          secondary={t(item.title)}
          secondaryTypographyProps={{ variant: 'subtitle2' }}
        />
      </ListItemButton>
    </ListItem>
  )
}

const ProtectedMenuItem = withProtectedComponent<MenuItemProps>(MenuItem)

interface BaseDrawerProps {
  backgroundColor: string
  hideBackButton: boolean
  organizationId?: string
  menuItems: SidebarItemProps[]
  testId?: string
}

const BaseDrawer = ({
  backgroundColor,
  hideBackButton,
  organizationId,
  menuItems,
  testId,
}: BaseDrawerProps) => {
  const { t } = useTranslation()

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: {
          width: drawerWidth,
          backgroundColor,
        },
      }}
      sx={{
        width: drawerWidth,
      }}
      data-testid={testId}
    >
      <Toolbar />
      <Box
        sx={{
          overflow: 'auto',
          mt: organizationId ? 0 : 4,
        }}
      >
        {!hideBackButton && organizationId && (
          <List disablePadding>
            <ListItemButton
              data-testid="side-bar-tab-all-organisation"
              component={Link}
              to="/"
              sx={{ gap: 1, pb: 3, pl: 2.5, pt: 5, ...MENU_BUTTON_HOVER_STATE }}
              color="secondary"
            >
              <ListItemIcon sx={{ color: 'secondary.main', minWidth: 'initial' }}>
                <ArrowBackIcon />
              </ListItemIcon>
              <ListItemText
                secondary={t('applicationBar.menu.allOrganizations')}
                secondaryTypographyProps={{ variant: 'subtitle2' }}
              />
            </ListItemButton>

            <Divider sx={{ borderColor: 'secondary.dark' }} />
          </List>
        )}
        <List disablePadding>
          {menuItems.map(
            (item) =>
              item.visible && (
                <ProtectedMenuItem
                  permission={item.permission}
                  key={item.link}
                  item={item}
                  organizationId={organizationId || ''}
                />
              ),
          )}
        </List>
      </Box>
    </Drawer>
  )
}

interface SingleOrgDrawerProps {
  backgroundColor: string
  hideBackButton: boolean
  displayReportsTab: boolean
  organizationId: string
}

const SingleOrgDrawer = ({
  backgroundColor,
  hideBackButton,
  displayReportsTab,
  organizationId,
}: SingleOrgDrawerProps) => {
  const { data: plans = [] } = useGetOrganizationPlansQuery({ organizationId })

  const displayEapRequestTab = minOnePlanHasOption(plans, ServiceOptionLabels.WORKPLACE_REFERRAL)
  const displayChallengesTab = minOnePlanHasService(plans, ServiceLabels.WELLNESS_CHALLENGES)

  const menuItems: SidebarItemProps[] = [
    {
      link: '/members',
      title: 'applicationBar.menu.members',
      icon: <PeopleIcon />,
      visible: true,
    },
    {
      link: '/requests',
      title: 'applicationBar.menu.eapRequests',
      icon: <AccountBox />,
      visible: displayEapRequestTab,
    },
    {
      link: '/reports',
      title: 'applicationBar.menu.reports',
      icon: <SvgIcon component={ReportsIcon} inheritViewBox />,
      visible: displayReportsTab,
    },
    {
      link: '/profile',
      title: 'applicationBar.menu.profile',
      icon: <SvgIcon component={OrganizationProfileIcon} inheritViewBox />,
      visible: true,
    },
    {
      link: '/billing',
      title: 'applicationBar.menu.billing',
      icon: <ReceiptIcon />,
      visible: true,
      permission: ScribePermission.LIST_INVOICES,
    },
    {
      link: '/challenges',
      title: 'applicationBar.menu.challenges',
      icon: <SvgIcon component={EmojiEventsIcon} inheritViewBox />,
      visible: displayChallengesTab,
      // FIXME: Remove this permission guard once we're ready to release challenges leaderboards
      // to all users.
      // Also: The 2 api endpoints used to display challenges are also limited to users with that scope.
      permission: ScribePermission.MANAGE_ORGANIZATIONS,
    },
  ]

  return (
    <BaseDrawer
      backgroundColor={backgroundColor}
      hideBackButton={hideBackButton}
      organizationId={organizationId}
      menuItems={menuItems}
      testId="single-org-drawer"
    />
  )
}

interface MultiOrgDrawerProps {
  backgroundColor: string
  hideBackButton: boolean
}

const MultiOrgDrawer = ({ backgroundColor, hideBackButton }: MultiOrgDrawerProps) => {
  const menuItems: SidebarItemProps[] = [
    {
      link: '/organizations',
      title: 'applicationBar.menu.organizations',
      icon: <ApartmentIcon />,
      visible: true,
    },
    {
      link: '/members',
      title: 'applicationBar.menu.members',
      icon: <PeopleIcon />,
      visible: true,
    },
    {
      link: '/users',
      sublink: '/user',
      title: 'applicationBar.menu.users',
      icon: <FolderSharedIcon />,
      visible: true,
      permission: ScribePermission.LIST_USERS,
    },
  ]

  return (
    <BaseDrawer
      backgroundColor={backgroundColor}
      hideBackButton={hideBackButton}
      menuItems={menuItems}
      testId="multi-org-drawer"
    />
  )
}

interface ApplicationSideBarProps {
  backgroundColor: string
  hideBackButton: boolean
  displayReportsTab: boolean
  organizationId?: string
}

export const ApplicationSideBar = ({
  backgroundColor,
  hideBackButton,
  displayReportsTab,
  organizationId,
}: ApplicationSideBarProps) => {
  if (organizationId) {
    return (
      <SingleOrgDrawer
        backgroundColor={backgroundColor}
        hideBackButton={hideBackButton}
        displayReportsTab={displayReportsTab}
        organizationId={organizationId}
      />
    )
  }

  return <MultiOrgDrawer backgroundColor={backgroundColor} hideBackButton={hideBackButton} />
}
