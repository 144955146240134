import { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query/react'

export interface ArgsProps extends FetchArgs {
  extraOptions?: {
    showErrorTooltip?: boolean
    successTooltip?: string
    errorCodes?: string
  }
}

export type CustomBaseQueryType = BaseQueryFn<
  string | ArgsProps,
  unknown,
  unknown,
  ArgsProps['extraOptions']
>

export type ScribeV2ErrorDataDetail = {
  msg: string
  type: string
  loc: string[]
}

export type ScribeV2ErrorData = {
  detail: string | ScribeV2ErrorDataDetail[]
}

export type ScribeV1ErrorDataDetail = {
  id: string | number
  status: string | number
  title: string
  code: string
  detail: string
  source: any
}

export type ScribeV1ErrorData = {
  errors: ScribeV1ErrorDataDetail[]
}

export type ErrorResponse = {
  status: number
  data: ScribeV1ErrorData | ScribeV2ErrorData
}

export const isErrorResponseV1 = (
  data: ScribeV1ErrorData | ScribeV2ErrorData,
): data is ScribeV1ErrorData => {
  return data.hasOwnProperty('errors')
}

export type ErrorCodeToMessage = {
  [code: string]: string
}

export type PaginationParams = {
  offset?: string | null
  limit?: string | null
}

export interface GetUsersParams extends PaginationParams {
  search?: string | null
}

export interface Collection<T> {
  data: T[]
}

export interface Paginated<T> extends Collection<T> {
  meta: {
    totalItems: number
    offset: number
  }
}

export interface Item<T> {
  data: T
}

export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
      ? Subset<K[attr]> | null
      : K[attr] extends object | null | undefined
        ? Subset<K[attr]> | null | undefined
        : K[attr]
}
