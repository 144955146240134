import { useCallback, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { exportRecordsToCsv } from 'app/lib/utils/export-records-to-csv'
import { getPlansIdToLabel } from 'app/lib/utils/helpers'
import { OrganizationRoute } from 'app/models/scribe.models'
import { useGetOrganizationPlansQuery } from 'app/redux/scribeApi'

export enum State {
  NORMAL = 'normal',
  EXPORTING = 'exporting',
  FAILED = 'failed',
}

type Props = Readonly<{
  state: State
  onSubmit: () => void
}>

export const ExportButtonDisplay: React.FC<Props> = ({ state, onSubmit }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'eligibilityRecordListPage.exportButton' })

  return (
    <Stack direction="row" alignItems="center" spacing={1} data-testid="export-csv-button">
      {state === State.FAILED && (
        <>
          <WarningAmberIcon color="error" fontSize="small" />
          <Typography color="error">{t('failed')}</Typography>
        </>
      )}
      {state === State.EXPORTING ? (
        <>
          <CircularProgress size={10} />
          <Button disabled>{t('exporting')}</Button>
        </>
      ) : (
        <Button variant="outlined" onClick={onSubmit}>
          {state === State.NORMAL ? t('export') : t('retry')}
        </Button>
      )}
    </Stack>
  )
}

export const ExportButton: React.FC = () => {
  const [state, setState] = useState<State>(State.NORMAL)
  const { organizationId } = useParams() as OrganizationRoute
  const { getAccessTokenSilently } = useAuth0()
  const { data: plans = [] } = useGetOrganizationPlansQuery({ organizationId })

  const planIdToLabel = useCallback(
    (planId?: number) => (planId ? getPlansIdToLabel(plans)[planId] : undefined),
    [plans],
  )

  const onSubmit = useCallback(() => {
    setState(State.EXPORTING)
    getAccessTokenSilently()
      .then((token) => exportRecordsToCsv(token, organizationId, planIdToLabel))
      .then(() => setState(State.NORMAL))
      .catch((err) => {
        console.log(err)
        setState(State.FAILED)
      })
  }, [organizationId, planIdToLabel, getAccessTokenSilently])

  return <ExportButtonDisplay state={state} onSubmit={onSubmit} />
}
