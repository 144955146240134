import { useCallback, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { NoResults } from 'app/lib/components/NoResults'
import { today, toISOdate } from 'app/lib/utils/date'
import {
  EligibilityRecord,
  EligibilityRecordStatus,
  Organization,
  PaginationV2,
} from 'app/models/scribe.models'
import { NoActiveRecords, NoSearchResults } from 'assets/images'

import { StatusTabs, TabType } from './StatusTabs'
import { HeaderKeys, TableDisplay } from './TableDisplay'

export { HeaderKeys }

type Props = Readonly<{
  records: ReadonlyArray<EligibilityRecord>
  pagination: PaginationV2
  onChange: (
    page: number,
    status?: string,
    eligible_on_or_after?: string,
    not_eligible_on_or_after?: string,
    search?: string,
  ) => void
  recordsCount: Organization['members']
  plansIdToLabel: {
    [key: string]: string
  }
  excludeHeaders?: HeaderKeys[]
}>

type StatusParams = {
  status?: EligibilityRecordStatus
  eligible_on_or_after?: string
  not_eligible_on_or_after?: string
}
export type SearchParams = {
  page: string
  search: string
} & StatusParams

export const EligibilityRecordsTable: React.FC<Props> = ({
  records,
  pagination,
  onChange,
  recordsCount,
  plansIdToLabel,
  excludeHeaders,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [page, setPage] = useState<number>(0)
  const [tabValue, setTabValue] = useState(TabType.ACTIVE)
  const [searchValue, setSearchValue] = useState('')

  const onTabChange = useCallback(
    (value: TabType) => {
      setPage(0)
      setTabValue(value)
      setSearchParams({
        page: '0',
        ...getStatusSearchParams(value),
      })
    },
    [setPage, setTabValue, setSearchParams],
  )

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setPage(pageNumber)
      if (searchValue) {
        setSearchParams({
          page: pageNumber.toString(),
          search: searchValue,
        })
      } else {
        setSearchParams({
          page: pageNumber.toString(),
          ...getStatusSearchParams(tabValue),
        })
      }
    },
    [setSearchParams, tabValue, searchValue],
  )

  useEffect(() => {
    const params: SearchParams = Object.fromEntries(searchParams.entries()) as any
    if (!params.page && !params.status) {
      // if no search params, initializes the page to the right ones, without changing the history
      setPage(0)
      setTabValue(TabType.ACTIVE)
      setSearchParams({ page: '0', status: EligibilityRecordStatus.ACTIVE }, { replace: true })
      return
    } else {
      setPage(parseInt(params.page))
      setTabValue(getTabValueFromParams(params))
      setSearchValue(params.search || '')
    }
    onChange(
      parseInt(params.page),
      params.status,
      params.eligible_on_or_after,
      params.not_eligible_on_or_after,
      params.search,
    )
  }, [searchParams, onChange, setPage, setSearchParams])

  if (searchValue) {
    return records.length === 0 ? (
      <NoMemberFound />
    ) : (
      <TableDisplay
        plansIdToLabel={plansIdToLabel}
        records={records}
        page={page}
        onPageChange={onPageChange}
        count={pagination.total}
      />
    )
  }

  return (
    <StatusTabs tabValue={tabValue} onTabChange={onTabChange} recordsCount={recordsCount}>
      {records.length === 0 ? (
        <NoRecords type={tabValue} />
      ) : (
        <TableDisplay
          plansIdToLabel={plansIdToLabel}
          records={records}
          page={page}
          onPageChange={onPageChange}
          count={pagination.total}
          excludeHeaders={excludeHeaders}
        />
      )}
    </StatusTabs>
  )
}

const getStatusSearchParams = (tabValue: TabType): StatusParams => {
  const todayISO = toISOdate(today())
  switch (tabValue) {
    case TabType.ACTIVE:
      return {
        status: EligibilityRecordStatus.ACTIVE,
      }
    case TabType.FUTURE:
      return {
        status: EligibilityRecordStatus.INACTIVE,
        eligible_on_or_after: todayISO,
      }
    case TabType.DEACTIVATED:
      return {
        status: EligibilityRecordStatus.INACTIVE,
        not_eligible_on_or_after: todayISO,
      }
    default:
      return {
        status: EligibilityRecordStatus.ACTIVE,
      }
  }
}

const getTabValueFromParams = (params: StatusParams) => {
  if (params.status === EligibilityRecordStatus.ACTIVE) {
    return TabType.ACTIVE
  }

  if (params.eligible_on_or_after) {
    return TabType.FUTURE
  }

  return TabType.DEACTIVATED
}

interface NoRecordProps {
  type: TabType
}

// FIXME: needs storybook
const NoRecords: React.FC<NoRecordProps> = ({ type }) => {
  const { t } = useTranslation()
  return type === TabType.ACTIVE ? (
    <NoResults
      title={t('eligibilityRecordListPage.eligibilityRecordsTable.noActiveMembers')}
      subtitle={t('eligibilityRecordListPage.eligibilityRecordsTable.getStarted')}
      img={<NoActiveRecords title="no_active_records" />}
    />
  ) : (
    <NoResults
      title={t('eligibilityRecordListPage.eligibilityRecordsTable.noDeactivatedMembers')}
      subtitle={t('eligibilityRecordListPage.eligibilityRecordsTable.accountsCanBeDeactivated')}
      img={<NoSearchResults title="no_results" />}
    />
  )
}

// FIXME: needs storybook
const NoMemberFound: React.FC = () => {
  const { t } = useTranslation()
  return (
    <NoResults
      title={t('eligibilityRecordListPage.eligibilityRecordsTable.noMemberFound')}
      img={<NoSearchResults title="no_member_found" />}
    />
  )
}
